import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { companyFetchData } from "../../store/entities/company";
import { faultsFetchData } from "../../store/entities/faults";

import { systemDeleteResourcesInRange } from "../../store/entities/system";
import { serviceDeleteData } from "../../store/entities/service";
import { budgetDeleteData } from "../../store/entities/budget";

import { useNavigate } from "react-router-dom";

import Welcome from "./Welcome";
import Advantages from "./Advantages";
import Steps from "./Steps";
import Assistance from "./Assistance";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Legal from "./Legal";

import CookieConsent from "react-cookie-consent";

import "./styles.scss";

const Landing = () => {

  const companyName = "PedirUnaGRUA.com";

  const dispatch = useDispatch();
  const { _id: companyId } = useSelector(state => state.entities.company);

  // routing

  let navigate = useNavigate();

  // methods

  const initStore = () => {
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("budgetId");
    localStorage.removeItem("serviceStatus");
    localStorage.removeItem("utcServiceExpire");
    localStorage.removeItem("service");
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteData());
    dispatch(systemDeleteResourcesInRange());
    navigate("/", { replace: true });
  };

  // useEffect

  useEffect(() => {
    const utcServiceExpire = localStorage.getItem("utcServiceExpire");
    if (utcServiceExpire) {
      const utcNow = Date.parse(new Date());
      if (utcServiceExpire > utcNow) {
        const ss = localStorage.getItem("serviceStatus");
        if (ss) {
          navigate("/servicio", { replace: true });
          return;
        }
        const bs = localStorage.getItem("budgetStatus");
        if (bs) {
          navigate("/solicitud", { replace: true });
          return;
        }
      } else {
        initStore();
      }
    }
    if (!companyId) dispatch(companyFetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyId) dispatch(faultsFetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  // render

  return (
    <div id="landing" className="Landing">

      <CookieConsent
        location="bottom"
        buttonText="ENTENDIDO"
        cookieName="consentCookies"
        style={{
          zIndex: 10000,
          background: "#333",
          fontSize: "16px",
          fontWeight: "normal",
          lineHeight: 1.5,
          fontFamily: "Kanit, sans-serif",
          color: "#fff",
        }}
        buttonStyle={{
          background: "#e51212",
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: .93,
          color: "#fff"
        }}
        expires={150}
      >
        Esta web utiliza cookies para mejorar la experiencia de usuario.
      </CookieConsent>

      <Welcome companyName={companyName} />
      <Advantages companyName={companyName} />
      <div className="divider">
        <img
          className="Divider__image left"
          src="/assets/images/divider-left.svg"
          alt=""
        />
      </div>
      <Steps />
      <Assistance />
      <div className="divider">
        <img
          className="Divider__image right"
          src="/assets/images/divider-right.svg"
          alt=""
        />
      </div>
      <AboutUs />
      <Contact />
      <Legal />

    </div>
  );
};

export default Landing;
