import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/api";

const initialState = {
  _id: null,
  sosBudget: {
    amount: null,
    deposit: null,
    time: null,
    distance: null,
    expirationDate: null,
  },
  status: null,
};

const slice = createSlice({
  name: "budget",

  initialState,

  reducers: {
    budgetDataReceived: (budget, action) => {
      localStorage.setItem("budgetId", action.payload._id);
      return { ...action.payload, status: "pending" };
    },
    budgetDataReceivedError: (budget, action) => {
      localStorage.removeItem("budgetId");
      budget.status = action.payload;
    },
    budgetStateUpdated: (budget, action) => {
      return { ...action.payload};
    },
    budgetStatusUpdated: (budget, action) => {
      budget.status = action.payload;
    },
    budgetDataDeleted: (budget, action) => {
      localStorage.removeItem("budgetId");
      return { ...initialState };
    },
  },
});

const { budgetDataReceived, budgetDataReceivedError, budgetStateUpdated, budgetStatusUpdated, budgetDataDeleted } = slice.actions;
export default slice.reducer;

// Action creators:

export const budgetFetchData = data => dispatch => {
  dispatch(
    apiCallBegan({
      url: "/service/serviceRequest",
      method: "post",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sosToken")}`,
      },
      onSuccess: budgetDataReceived.type,
      onError: budgetDataReceivedError.type,
    })
  );
};

export const budgetUpdateState = statusSaved => dispatch => {
  dispatch(budgetStateUpdated(statusSaved));
}

export const budgetUpdateStatus = newStatus => dispatch => {
  dispatch(budgetStatusUpdated(newStatus));
};

export const budgetDeleteData = () => dispatch => {
  dispatch(budgetDataDeleted());
};
