import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/api";

const slice = createSlice({
  name: "faults",
  initialState: {
    list: [],
  },

  reducers: {
    faultsDataReceived: (faults, action) => {
      faults.list = action.payload;
    },
  },
});

const { faultsDataReceived } = slice.actions;
export default slice.reducer;

// Action creators:

export const faultsFetchData = () => dispatch => {
  dispatch(
    apiCallBegan({
      url: "faults/all",
      method: "get",
      data: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sosToken")}`,
      },
      onSuccess: faultsDataReceived.type,
    })
  );
};
