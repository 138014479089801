const getTimeLeft = utcExpiration => {
  const utcNow = Date.parse(new Date());
  const difference = utcExpiration - utcNow;

  let timeLeft = {};

  if (difference >= 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      secondsToTimeout: Math.floor(difference / 1000),
    };
  } 

  return timeLeft;
};

export default getTimeLeft;
