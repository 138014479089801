import { useState, useEffect } from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import "../sharedStyles.scss";
import "./styles.scss";


const advantageList = [
  { icon: "fast", title: "Solicitud rápida y sencilla", text: "Completa un pequeño formulario y tendrás la grúa en camino." },
  { icon: "pay", title: "Presupuesto online y pago seguro", text: "Presupuesto con la grúa más cercana a ti. Pago con tarjeta al momento." },
  { icon: "info", title: "Informado en todo momento", text: "Visualiza en tiempo real la ubicación de la grúa durante el servicio." },
  { icon: "adjusted-pay", title: "Pagas solo lo que necesitas", text: "Calculamos el precio de la asistencia adaptándonos a tus necesidades." },
  { icon: "compatibility", title: "Compatible con tu aseguradora", text: "Si tienes asistencia incluida, pídenos servicio y reclama a tu compañía." },
  { icon: "connected", title: "Grúas conectadas en tiempo real", text: "Geolocalizamos todas las grúas, para presupuestar y gestionar cada servicio." },
];

const Advantages = ({ companyName }) => {

  const { height, width } = useWindowDimensions();

  const totalItmes = 6;
  const [itemsToView, setItemsToView] = useState(3);
  const [steps, setSteps] = useState(3);

  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevBtn = () => {
    if (currentIndex === 0) {
      return;
    }
    setCurrentIndex(currentIndex - 1);
  };

  const handleNextBtn = () => {
    if (currentIndex === steps) return;
    setCurrentIndex(currentIndex + 1);
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width >= 1440) {
      setItemsToView(4);
      setSteps(2);
    } else if (width >= 1024) {
      setItemsToView(3);
      setSteps(3);
    } else {
      setItemsToView(6);
      setSteps(6);
    }
    setCurrentIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);

  useEffect(() => {
    if (currentIndex === 0) {
      setPrevDisabled(true);
      setNextDisabled(false);
      return;
    }
    if (currentIndex === steps) {
      setPrevDisabled(true);
      setNextDisabled(false);
      return;
    }
    setPrevDisabled(true);
    setNextDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  return (
    <div id="ventajas" className="Section bg-white">
      <div className="Section__wrapper ">

        <div className="Section__header-big-text advantages">
          Ventajas de {companyName}
        </div>

        <div className="Advantages__prev-btn-wrapper">
          <img
            className={`Advantages__prev-btn-image ${currentIndex <= 0 ? "disabled" : ""}`}
            src={`/assets/icons/prev${currentIndex <= 0 ? "-disabled" : ""}.svg`}
            alt=""
            onClick={handlePrevBtn}
          />
        </div>

        <div key={currentIndex} className="Advantages flex row h-around">

          {advantageList.filter((item, index) => {
            return (((index >= currentIndex) && (index <= (currentIndex + steps + 1))) ? item : false);
          }).map((advantage, index, currentIndex) => {
            return (
              <div
                key={advantage.icon}
                className={`Advantages__item flex h-center v-start ${index < currentIndex ? "hide" : ""}`}
              >
                <img
                  className="Advantages__item-icon"
                  src={`/assets/images/home-advantages-${advantage.icon}.svg`}
                  alt=""
                />
                <div className="Advantages__item-title">{advantage.title}</div>
                <div className="Advantages__item-text">{advantage.text}</div>
              </div>
            );
          })}
        </div>

        <div className="Advantages__next-btn-wrapper">
          <img
            className={`Advantages__next-btn-image ${currentIndex >= steps ? "disabled" : ""}`}
            src={`/assets/icons/next${currentIndex >= steps ? "-disabled" : ""}.svg`}
            alt=""
            onClick={handleNextBtn}
          />
        </div>

      </div>
    </div>
  );
};

export default Advantages;
