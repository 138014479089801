import _ from "lodash";
import axios from "axios";
import axiosRetry from "axios-retry";

import { createAction } from "@reduxjs/toolkit";

import { systemIsLoading } from "../entities/system";

export const apiCallBegan = createAction("api/callBegan");
export const apiCallSuccess = createAction("api/callSuccess");
export const apiCallFailed = createAction("api/callFailed");

const baseURL = process.env.REACT_APP_API;

axiosRetry(axios, {retries: 3, retryDelay: (retryCount) => retryCount * 1000});

const api =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type !== apiCallBegan.type) return next(action);

    const { url, method, data, headers, onStart, onSuccess, onError } = action.payload;

    if (onStart) dispatch({ type: onStart });

    // This middleware handles 3 actions. To reach the onSuccess or onError
    // actions after de http request, is necessary to make the following call.

    next(action);

    try {
      // axios.request returns a promise.
      if (window.location.pathname !== "/servicio") dispatch(systemIsLoading(true));
      const response = await axios.request({
        baseURL,
        url,
        method,
        data: method === "post" || method === "put" ? data : null,
        headers,
      });
      // General
      dispatch(systemIsLoading(false));
      dispatch(apiCallSuccess(response.data));
      // Specific --- p.e.: onSuccess -> "usersReceived"
      if (onSuccess) {
        dispatch({ type: onSuccess, payload: _.isEmpty(response.data) ? data : response.data.payload });
      }
    } catch (error) {
      // General
      dispatch(systemIsLoading(false));
      // Specific
      if (onError && error.response) dispatch({ type: onError, payload: error.response.data || error});
      else console.log(error);
    }
  };

export default api;
