import { useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";

import "../sharedStyles.scss";
import "./styles.scss";

const AboutUs = () => {

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/solicitud", {replace: true});
  }

  return (

    <div id="nosotros" className="Section bg-gray">

      <div className="Section__wrapper ">

        <div className="AboutUs__image">
          <img src="/assets/images/home-about-image.png" alt="" />
        </div>

        <div className="AboutUs__content">
          <div className="Section__header-big-text flex column h-center v-center">
            <span>Especialistas en software de gestión y plataformas de movilidad</span>
          </div>
          <div className="Section__text flex">
            Llevamos más de 20 años dando servicios tecnológicos de asistencia, y ahora en colaboración con Nexus, somos la primera plataforma europea de asistencia en carretera.
          </div>
          <div className="Section__text aditional-text flex">
            Gracias a ello, conseguimos que puedas pedir tu servicio de grúa online, pagar con tu tarjeta y tener acceso en tiempo real a la grúa que te va a realizar el servicio.
          </div>
          <div id="about_us_btn_wrapper" className="Section__request-button-wrapper left">
            <Button id="about_us_btn" className="Section__request-button" onClick={onClick}>
              PEDIR GRÚA
            </Button>
          </div>
        </div>

      </div>

    </div>



  );
};

export default AboutUs;
