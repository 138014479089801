import "./styles.scss";

const TimeoutLine = ({ percentage, shadow = false }) => {
  return (
    <div className="TimeoutLine">
      <div className={`TimeoutLine__progress ${shadow ? "shadow" : ""}`} style={{ width: `${percentage}%` }} />
    </div>
  );
};

export default TimeoutLine;
