import "../sharedStyles.scss";
import "./styles.scss";

const Contact = () => {
  return (
    <div className="Section bg-company">
      <div className="Contact flex row v-center">

        <img className="Contact__logo" src="/assets/images/logo-white.svg" alt="" />

        <div className="Contact__slogan">
          El futuro de la asistencia, al alcance de todos
        </div>

        <div className="Contact__info-wrapper">

          <div className="Contact__info flex column h-center v-center">
            <div className="Contact__info-header">
              Información de contacto:
            </div>
            <div className="Contact__info-data">
              info@pedirunagrua.com
            </div>
          </div>

          <div className="Contact__info flex column h-center v-center">
            <div className="Contact__info-header">
              Estamos en:
            </div>
            <div className="Contact__info-data">
              Com. de Madrid, España
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Contact;
