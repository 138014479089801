import { Button } from "@material-ui/core";

import "./styles.scss";

const ModalCardNotify = ({ modal, onClick }) => {
  return (
    <div className="ModalCardNotify flex row h-center v-center">
      <div className="ModalCardNotify__wrapper">
        <div className="ModalCardNotify__main-header">Error en la operación</div>
        <div className="ModalCardNotify__header">{modal.header}</div>
        <div className="ModalCardNotify__comment">{modal.comment}</div>
        <Button className="ModalCardNotify__action-button" style={{ textTransform: "none" }} onClick={onClick}>
          Aceptar
        </Button>
      </div>
    </div>
  );
};

export default ModalCardNotify;
