import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import _ from "lodash";

import "./styles.scss";
import mapStyles from "./mapStyles";

const google = (window.google = window.google ? window.google : {});

let pickupCoords = null;
let deliveryCoords = null;

const Map = () => {

  // redux-store

  const { servicePickupPlace, serviceDeliveryPlace } = useSelector(state => state.entities.service);

  // useRef

  const mapRef = useRef();
  const mapElementRef = useRef();
  const pickupRef = useRef();
  const deliveryRef = useRef();
  const directionsServiceRef = useRef();
  const directionsRendererRef = useRef();

  // methods

  const clearRoutes = () => {
    directionsRendererRef.current?.setMap(null);
  };

  const clearMarkers = () => {
    deliveryRef.current?.setMap(null);
    pickupRef.current?.setMap(null);
  };

  const initMarkers = () => {
    clearRoutes();
    clearMarkers();
    if (!_.isNull(pickupCoords)) {
      pickupRef.current = new google.maps.Marker({
        position: {
          lat: pickupCoords[0],
          lng: pickupCoords[1],
        },
        map: mapRef.current,
        icon: "/assets/icons/pin-car-shadow.svg",
      });
    }
    if (!_.isNull(deliveryCoords)) {
      deliveryRef.current = new google.maps.Marker({
        position: {
          lat: deliveryCoords[0],
          lng: deliveryCoords[1],
        },
        map: mapRef.current,
        icon: "/assets/icons/pin-flag-shadow.svg",
      });
    }
  };

  const drawRoute = () => {
    if (_.isNull(pickupCoords) || _.isNull(deliveryCoords)) {
      return;
    }
    try {
      const currentRoute = {
        origin: new google.maps.LatLng(pickupCoords[0], pickupCoords[1]),
        destination: new google.maps.LatLng(deliveryCoords[0], deliveryCoords[1]),
        travelMode: "DRIVING",
      };
      directionsServiceRef.current = new google.maps.DirectionsService();
      directionsRendererRef.current = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeOpacity: .8,
          strokeWeight: 3,
          strokeColor: "#e51212"
        }
      });
      directionsRendererRef.current.setMap(mapRef.current);
      directionsServiceRef.current.route(currentRoute, response => {
        directionsRendererRef.current.setDirections(response);
      });
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const goToBounds = () => {
    let bounds = new google.maps.LatLngBounds();
    if (!_.isNull(pickupCoords)) bounds.extend({ lat: pickupCoords[0], lng: pickupCoords[1] });
    if (!_.isNull(deliveryCoords)) bounds.extend({ lat: deliveryCoords[0], lng: deliveryCoords[1] });
    if (!bounds.isEmpty()) {
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      mapRef.current.fitBounds(bounds); // auto-zoom
    }
  };

  // useEffect

  useEffect(() => {
    if (_.isNull(servicePickupPlace)) {
      pickupCoords = null;
    } else {
      if (_.isFunction(servicePickupPlace.geometry.location.lat)) {
        pickupCoords = [servicePickupPlace.geometry.location.lat(), servicePickupPlace.geometry.location.lng()];
      } else {
        pickupCoords = [servicePickupPlace.geometry.location.lat, servicePickupPlace.geometry.location.lng];
      }
    }
    if (_.isNull(serviceDeliveryPlace)) {
      deliveryCoords = null;
    } else {
      if (_.isFunction(serviceDeliveryPlace.geometry.location.lat)) {
        deliveryCoords = [serviceDeliveryPlace.geometry.location.lat(), serviceDeliveryPlace.geometry.location.lng()];
      } else {
        deliveryCoords = [serviceDeliveryPlace.geometry.location.lat, serviceDeliveryPlace.geometry.location.lng];
      }
    }
    if(!mapRef.current) {
      mapRef.current = new google.maps.Map(mapElementRef.current, {
        center: { lat: 40.4166161, lng: -3.7037883 },
        zoom: 8,
        minZoom: 4,
        maxZoom: 19,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        options: { styles: mapStyles },
      });
    }    
    initMarkers();
    drawRoute();
    goToBounds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicePickupPlace, serviceDeliveryPlace]);

  // render

  return (
    <div className="Map">
      <div ref={mapElementRef} className="Map__map" />
    </div>
  );
};

export default Map;
