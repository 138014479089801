import "./styles.scss";

const Spinner = () => {
  return (
    <div className="Spinner flex h-center v-center">
      <img className="Spinner__image" src="/assets/images/spinner.svg" alt="" />
    </div>
  );
};

export default Spinner;
