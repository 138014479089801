import { useState, useEffect, memo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { systemDeleteResourcesInRange } from "../../store/entities/system";
import { serviceDeleteData, serviceDeleteStatus } from "../../store/entities/service";
import { budgetUpdateStatus, budgetDeleteData } from "../../store/entities/budget";

import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import Map from "../../components/Map";

import _ from "lodash";

import StepProgress from "../../components/StepProgress";
import TimeoutLine from "../../components/TimeoutLine";
import ActionButton from "../../components/ActionButton";

import ModalNotify from "../../components/ModalNotify";
import modalNotifyContent from "../../components/ModalNotify/modalNotifyContent";

import getTimeLeft from "../../helpers/getTimeLeft";
import padStart from "../../helpers/padStart";

import "../../styles/global.scss";
import "./styles.scss";

let today = new Date();
today = today.getDate() + "/" + parseInt(today.getMonth() + 1) + "/" + today.getFullYear();

const ServiceMap = memo(Map);

let interval = null;
let initialSecondsToTimeout;
let utcExpiration;

const Budget = () => {

  // resize-observer

  const { height, width } = useWindowDimensions();

  // redux-store

  const dispatch = useDispatch();

  const { _id: budgetId, sosBudget: budgetData, status: budgetStatus } = useSelector(state => state.entities.budget);
  const budgetTotalAmount = budgetData.amount + budgetData.deposit;

  // routing

  let navigate = useNavigate();

  // useState

  const [showMapView, setShowMapView] = useState(false);

  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(utcExpiration));

  const [showTimeOver, setShowTimeOver] = useState(false);

  // methods

  const initStore = () => {
    localStorage.removeItem("budgetId");
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("service");
    localStorage.removeItem("serviceStatus");
    localStorage.removeItem("utcServiceExpire");
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteData());
    dispatch(systemDeleteResourcesInRange());
    navigate("/", { replace: true });
  };

  const handleGoBack = () => {
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("serviceStatus");
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteStatus());
    navigate("/solicitud", { replace: true });
  };

  const handleTimeOverNotify = () => {
    setShowTimeOver(false);
    handleGoBack();
  };

  const handleAcceptBudget = async () => {
    localStorage.setItem("budgetStatus", "accepted");
    localStorage.setItem("comingFrom", "Budget");
    dispatch(budgetUpdateStatus("accepted"));
    navigate("/pago", { replace: true });
  };

  // useEffects

  useEffect(() => {
    if (width < 960) setShowMapView(false);
    else setShowMapView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);

  useEffect(() => {
    const comingFrom = localStorage.getItem("comingFrom");
    localStorage.removeItem("comingFrom");

    const utcServiceExpire = localStorage.getItem("utcServiceExpire");
    const utcNow = Date.parse(new Date());

    if (!comingFrom || comingFrom !== "RequestForm") {
      if (utcServiceExpire && utcServiceExpire > utcNow) {
        navigate("/solicitud", { replace: true });
        return;
      }
      initStore();
      return;
    }

    if (utcServiceExpire && utcServiceExpire <= utcNow) {
      navigate("/", { replace: true });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (budgetId) {
      utcExpiration = Date.parse(budgetData.expirationDate);
      initialSecondsToTimeout = getTimeLeft(utcExpiration).secondsToTimeout;
      setIsCountdownActive(budgetStatus === "accepted" ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetId]);

  useEffect(() => {
    if (_.isNull(timeLeft)) return;
    if (timeLeft.secondsToTimeout <= 0) {
      clearInterval(interval);
      interval = null;
      setIsCountdownActive(false);
      setShowTimeOver(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  useEffect(() => {
    if (!isCountdownActive) return;
    interval = setInterval(() => {
      setTimeLeft(getTimeLeft(utcExpiration));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountdownActive]);

  // render

  return (
    <div id="App">

      {/* CONTAINER: HEADER */}

      <div className="App__container-header" >

        <a className="go-back-icon" onClick={handleGoBack}>
          <img src="/assets/icons/go-back.svg" alt="" />
        </a>

        <div className="header-info">
          <StepProgress step={2} label="Aceptar presupuesto" />
        </div>

        <img
          className="company-logo"
          src="/assets/images/logo-color.svg"
          alt=""
        />

      </div>

      {/* CONTAINER: GLOBAL */}

      <div className="App__container-global flex row h-between">

        {/* CONTAINER: MAP */}

        <div className="App__container-map">{showMapView && <ServiceMap update={false} />}</div>

        {/* CONTAINER: BUDGET */}

        <div className="App__container-main Budget">

          <div className={`Budget__timeout-wrapper ${_.isEmpty(timeLeft) ? "hide" : " "}`}>
            <TimeoutLine
              percentage={Math.floor((timeLeft.secondsToTimeout * 100) / initialSecondsToTimeout)}
              shadow={false}
            />
            <div className="Budget__timeout">
              {padStart(timeLeft.minutes, 2)}:{padStart(timeLeft.seconds, 2)} min.
            </div>
          </div>

          <div className="Budget__detail" style={{ backgroundImage: `url("/assets/images/budget-ticket-paper.png")` }}>

            <div className="Budget__detail-preheader flew row h-between v-center">
              <img
                className="Budget__detail-preheader-logo"
                src="/assets/images/logo-color.svg"
                alt=""
              />
              <div className="Budget__detail-preheader-today">{today}</div>
            </div>

            <div className="Budget__detail-header">PRESUPUESTO DE ASISTENCIA</div>

            <div className="Budget__detail-time-distance-wrapper flex row h-evenly v-start">
              <div className="Budget__detail-time"><span className="header">Llega en</span> {budgetData.time} min</div>
              <div className="Budget__detail-distance"><span className="header">Está a</span> {budgetData.distance} km</div>
            </div>

            <div className="Budget__detail-cost-wrapper flex column h-between v-between">
              <div className="Budget__detail-cost flex row h-between">
                <div className="Budget__detail-concept ">Precio del servicio <span>(IVA incl.)</span></div>
                <div className="Budget__detail-amount">{budgetData.amount} €</div>
              </div>
              <div className="Budget__detail-cost flex row h-between">
                <div className="Budget__detail-concept ">Depósito <span>(Gastos imprevistos)</span></div>
                <div className="Budget__detail-amount">{budgetData.deposit} €</div>
              </div>
              <div className="Budget__detail-cost flex row h-between">
                <div className="Budget__detail-concept total">Autorización total</div>
                <div className="Budget__detail-amount total">{budgetTotalAmount} €</div>
              </div>
              <div className="Budget__detail-card-info">*Se realizará una preautorización en tu tarjeta del importe total. El depósito solo se utilizaría si hubiera gastos extras no incluidos como peajes, carritos, etc. Al finalizar el servicio, solo se cargará el precio del servicio y posibles gastos imprevistos.</div>
            </div>

          </div>

          <div className="Budget__footer">
            <ActionButton label="Aceptar" disabled={false} onClick={handleAcceptBudget} />
          </div>

        </div>

      </div>

      {/* WARNING AND NOTIFY */}

      {showTimeOver && <ModalNotify modal={modalNotifyContent.budgetTimeout} onClick={handleTimeOverNotify} />}

    </div>
  );
};

export default Budget;
