import "./styles.scss";

const StepProgress = ({ step, label }) => {
  const stepIcon = `step-${step}.svg`;
  return (
    <div className="StepProgress flex h-center v-between">
      <img className="StepProgress__image" src={`/assets/icons/${stepIcon}`} alt="" />
      <div className="StepProgress__label">{`${step + ". "}${label.toUpperCase()}`}</div>
    </div>
  );
};

export default StepProgress;
