import { Button } from "@material-ui/core";

import "./styles.scss";

const ModalNotify = ({ modal, onClick, close = false, onClose = () => { } }) => {
  return (
    <div className="ModalNotify flex row h-center v-center">
      <div className="ModalNotify__wrapper">
        {close && (
          <img
            className="ModalNotify__close-button"
            src="/assets/icons/close.svg"
            alt=""
            onClick={onClose}
          />
        )}
        <div className="ModalNotify__header">{modal.header}</div>
        <div className="ModalNotify__content">{modal.content}</div>
        <Button className="ModalNotify__action-button" style={{ textTransform: "none" }} onClick={onClick}>
          {modal.buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default ModalNotify;
