import "../sharedStyles.scss";
import "./styles.scss";

const Assistance = () => {
  return (
    <div id="servicio" className="Section bg-white">

      <div className="Section__wrapper flex h-between v-center">

        <div className="Assistance__content">
          <div className="Section__header-big-text flex column h-center v-center">
            <span>La primera red nacional de asistencia online</span>
          </div>
          <ul className="Assistance__observations">
            <li className="Assistance__note">
              Tenemos cientos de grúas con cobertura en toda España
            </li>
            <li className="Assistance__note">
              Realizamos reparación in situ, recogida y entrega, o traslado
            </li>
            <li className="Assistance__note">
              Servicios de urgencia 24 horas al día, toda la semana
            </li>
            <li className="Assistance__note">
              Tenemos acuerdos con talleres y aseguradoras por si lo necesitas
            </li>
            <li className="Assistance__note">
              Nos encargamos de todo el papeleo: seguros, documentación, etc.
            </li>
            <li className="Assistance__note">
              Toda clase de grúas para rescatar cualquier tipo de vehículo
            </li>
            <li className="Assistance__note">
              Pago online de confianza con un banco internacional
            </li>
          </ul>
        </div>

        <div className="Assistance__map">
          <img src="/assets/images/spain-map.svg" alt="" />
        </div>

      </div>

    </div>
  );
};

export default Assistance;
