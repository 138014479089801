import { useFormContext, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

const TextInput = props => {
  const handleChange = (a, b, c, d) => {
    props.onChange(a, b, c, d);
    props.handleChange && props.handleChange(a);
  };

  const handleBlur = (a, b, c, d) => {
    props.onBlur(a, b, c, d);
    props.handleBlur && props.handleBlur(a);
  };

  const handleFocus = (a, b, c, d) => {
    props.onFocus && props.onFocus(a, b, c, d);
    props.handleFocus && props.handleFocus(a);
  };

  return <TextField {...props} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus} />;
};

const Input = props => {
  const { label, name, errorobj } = props;
  const { control } = useFormContext();

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message || "Campo obligatorio";
  }

  return (
    <div className="Input">
      <Controller
        as={TextInput}
        name={name}
        control={control}
        defaultValue=""
        label={label}
        variant="outlined"
        error={isError}
        helperText={errorMessage}
        {...props}
      />
    </div>
  );
};

export default Input;
