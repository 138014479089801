import { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { systemCheckResourcesInRange, systemDeleteResourcesInRange } from "../../store/entities/system";
import { companyFetchData } from "../../store/entities/company";
import { faultsFetchData } from "../../store/entities/faults";
import { serviceUpdateData, serviceDeleteData, serviceUpdatePickup, serviceUpdateDelivery } from "../../store/entities/service";
import { budgetFetchData, budgetDeleteData } from "../../store/entities/budget";

import { useForm, FormProvider } from "react-hook-form";

import { useNavigate } from 'react-router-dom';

import useWindowDimensions from "../../hooks/useWindowDimensions";

import Map from "../../components/Map";

import _ from "lodash";

import {
  FormControl,
  FormControlLabel,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Checkbox,
} from "@material-ui/core";

import InputAddress from "../../components/InputAddress";

import StepProgress from "../../components/StepProgress";
import ActionButton from "../../components/ActionButton";

import ModalTextHtmlFile from "../../components/ModalTextHtmlFile";

import ModalNotify from "../../components/ModalNotify";
import modalNotifyContent from "../../components/ModalNotify/modalNotifyContent";

import "./styles.scss";

const googleKey = process.env.REACT_APP_GOOGLE_KEY;

const headers = {
  "formHeader": <StepProgress step={1} label="Rellena el formulario" />,
  "pickupHeader": <div className="header-info__label">¿DÓNDE RECOGEMOS EL VEHÍCULO?</div>,
  "deliveryHeader": <div className="header-info__label">¿DÓNDE ENTREGAMOS EL VEHÍCULO?</div>,
};

const RequestForm = () => {

  // resize-observer

  const { height, width } = useWindowDimensions();

  // redux-store

  const dispatch = useDispatch();

  const { resourcesInRange } = useSelector(state => state.entities.system);
  const { _id: companyId } = useSelector(state => state.entities.company);
  const { list: faultsList } = useSelector(state => state.entities.faults);
  const { data: serviceData, servicePickupPlace, serviceDeliveryPlace } = useSelector(state => state.entities.service);
  const { status: budgetStatus } = useSelector(state => state.entities.budget);

  // routing

  let navigate = useNavigate();

  // react-hook-form

  const methods = useForm({
    mode: "onBlur",
  });

  const { formState, register, setValue, trigger, errors, reset, handleSubmit } = methods;
  const { isValid } = formState;

  // useRef

  const pickupRef = useRef();
  const pickupAddressRef = useRef(null);
  const pickupNotesRef = useRef(null);

  const deliveryRef = useRef();
  const deliveryAddressRef = useRef(null);
  const deliveryNotesRef = useRef(null);

  // useState

  const [tacAccepted, setTacAccepted] = useState(false);
  const [showTac, setShowTac] = useState(false); // TAC: Terms And Conditions

  const [currentScroll, setCurrentScroll] = useState(0);

  const [currentHeader, setCurrentHeader] = useState();

  const [pickupPlace, setPickupPlace] = useState(null);

  const [deliveryRequired, setDeliveryRequired] = useState(false);
  const [deliveryKey, setDeliveryKey] = useState(0);

  const [deliveryPlace, setDeliveryPlace] = useState(null);
  const [showMapView, setShowMapView] = useState(false);
  const [isGeolocationActive, setIsGeolocationActive] = useState(true);
  const [geolocationError, setGeolocationError] = useState(null);
  const [showGeolocationNotify, setShowGeolocationNotify] = useState(false);

  const [faultSelected, setFaultSelected] = useState("");
  const [isInParking, setIsInParking] = useState(false);

  const [showPickupNotes, setShowPickupNotes] = useState(false);

  const [showDelivery, setShowDelivery] = useState(false);
  const [showDeliveryNotes, setShowDeliveryNotes] = useState(false);

  const [showResourcesNotify, setShowResourcesNotify] = useState(false);

  // methods

  const initStore = () => {
    localStorage.removeItem("assisted");
    localStorage.removeItem("budgetId");
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("service");
    localStorage.removeItem("serviceStatus");
    localStorage.removeItem("utcServiceExpire");
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteData());
    dispatch(systemDeleteResourcesInRange());
    navigate("/", { replace: true });
  };

  const handleScrollToElement = event => {
    event.target.scrollIntoView({block: "center", behavior: "smooth"});
    return;
  }

  const handleReturnToForm = () => {
    setCurrentHeader("formHeader");
  };

  const handleReturnToWeb = () => {
    setShowResourcesNotify(false);
    initStore();
  };

  const handleAcceptTac = () => {
    setTacAccepted(!tacAccepted);
  }

  const handleCloseResourcesNotify = () => {
    setShowResourcesNotify(false);
    dispatch(systemDeleteResourcesInRange());
  };

  const handleCloseGeolocationNotify = () => {
    setShowGeolocationNotify(false);
    setGeolocationError(null);
  };

  const handleChangeFault = event => {
    setFaultSelected(event.target.value);
    const { deliveryRequired } = event.target.value;
    setDeliveryRequired(deliveryRequired);
    if (!deliveryRequired) {
      setDeliveryPlace(null);
      deliveryAddressRef.current = "";
      dispatch(serviceUpdateDelivery(null));
      setValue("delivery", "");
      trigger("delivery");
      deliveryRef.current.value = "";
    }
    setShowDelivery(deliveryRequired ? true : false);
  };

  const handleChangeInParking = event => setIsInParking(event.target.value === "Sí" ? true : false);

  // address methods

  const findInGoogleAddress = (place, type) => {
    const component = place.address_components.find(item => item.types[0] === type);
    return component ? component.long_name : "";
  };

  const handleChangeAddress = (_, place, addressType, checkResources = true, geolocation = false) => {
    /* Notes:
        _           : fullAddress,
        place       : special Object with "address_components", formatted_address, geometry and html_attributions (e.g.: servicePickupPlace & pickupPlace)
        addressType : ["pickup"|"delivery"]
        checkResources: default true
        geoloocation: default false
    */
    try {

      if (!place) {
        if (addressType === "pickup") {
          setPickupPlace(null);
          pickupAddressRef.current = "";
          dispatch(serviceUpdatePickup(null));
        }
        if (addressType === "delivery") {
          setDeliveryPlace(null);
          deliveryAddressRef.current = "";
          dispatch(serviceUpdateDelivery(null));
        }
        setValue(addressType, "");
        trigger(addressType);
        return;
      }

      let coords;
      if (!geolocation) {
        coords = [place.geometry.location.lat(), place.geometry.location.lng()];
      } else {
        coords = [place.geometry.location.lat, place.geometry.location.lng];
      }

      const _address = {
        fullAddress: place.formatted_address,
        streetNumber: findInGoogleAddress(place, "street_number"),
        street: findInGoogleAddress(place, "route"),
        city: findInGoogleAddress(place, "locality"),
        province: findInGoogleAddress(place, "administrative_area_level_2"),
        country: findInGoogleAddress(place, "country"),
        zip: findInGoogleAddress(place, "postal_code"),
        coordinates: coords,
      };

      if (addressType === "pickup") {
        setPickupPlace(place);
        pickupAddressRef.current = _address;
        dispatch(serviceUpdatePickup(place));
      }
      if (addressType === "delivery") {
        setDeliveryPlace(place);
        deliveryAddressRef.current = _address;
        dispatch(serviceUpdateDelivery(place));
      }

      setValue(addressType, _address.fullAddress);
      trigger(addressType);

      dispatch(systemDeleteResourcesInRange());

      if (checkResources && addressType === "pickup") {
        dispatch(systemCheckResourcesInRange(coords, _address.fullAddress)); // modify store: service.resourcesInRange value.
      }

      // Note: useEffect [service.resourcesInRange]
    } catch (e) {
      //console.log("RequestForm - Error in handleChangeAddress method");
      //console.log(e);
      return null;
    }
  };

  // geolocation

  const getLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition(async position => {

        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        const place = await fetchPlaceByCoords([lat, lng]);
        const _address = await parsePlaceByCoords(place);
        const fullAddress = _address.fullAddress;
        handleChangeAddress(fullAddress, place, "pickup", true, true);

      }, (e) => {
        /*
          GeolocationPositionError - e.code = 1: PERMISION_DENIED
          GeolocationPositionError - e.code = 2: POSITION_UNAVAILABLE
          GeolocationPositionError - e.code = 3: TIMEOUT
        */
        switch (e.code) {
          case 1:
            setGeolocationError("geolocationServiceForbitten");
            break;
          case 2:
          case 3:
            setGeolocationError("geolocationServiceError");
            break;
          default:
            setGeolocationError("geolocationServiceError");
        }
        setShowGeolocationNotify(true);
      });
    } catch (e) {
      //console.log("RequestForm - Error in getLocation method");
      //console.log(e);
      return null;
    }
  };

  const fetchPlaceByCoords = async coords => {
    try {
      const request = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&location_type=GEOMETRIC_CENTER&language=es&key=${googleKey}`;
      const jsonResponse = await fetch(request);
      const response = await jsonResponse.json();
      const results = response.results;
      let totalResults = results.length;
      for (let i = 0; i < totalResults; i++) {
        if (results[i].address_components[0].types[0] === "street_number") return results[i];
      }
      return results[0];
    } catch (e) {
      //console.log("RequestForm - Error in fetchPlaceByCoords method");
      //console.log(e);
      return null;
    }
  };

  const parsePlaceByCoords = async place => {
    try {
      const _address = {
        fullAddress: place.formatted_address,
        streetNumber: findInGoogleAddress(place, "street_number"),
        street: findInGoogleAddress(place, "route"),
        city: findInGoogleAddress(place, "locality"),
        province: findInGoogleAddress(place, "administrative_area_level_2"),
        country: findInGoogleAddress(place, "country"),
        zip: findInGoogleAddress(place, "postal_code"),
        coordinates: [place.geometry.location.lat, place.geometry.location.lng],
      };
      return _address;
    } catch (e) {
      //console.log("RequestForm - Error in parsePlaceByCoords method");
      //console.log(e);
      return null;
    }
  };

  const getPickupAddress = async (address, spp = null) => {
    try {
      if (!spp) setPickupPlace(servicePickupPlace);
      else setPickupPlace(spp);
      pickupAddressRef.current = address;
    } catch (e) {
      setPickupPlace(null);
      pickupAddressRef.current = "";
    }
  };

  const getDeliveryAddress = async (address, sdp = null) => {
    try {
      if (!sdp) setDeliveryPlace(serviceDeliveryPlace);
      else setDeliveryPlace(sdp);
      deliveryAddressRef.current = address;
      setShowDelivery(true);
    } catch (e) {
      setDeliveryPlace(null);
      deliveryAddressRef.current = "";
    }
  };

  // submit method

  const onSubmit = async data => {
    data.pickup = { notes: data.pickupNotes || "", address: { ...pickupAddressRef.current } };
    delete data.pickupNotes;

    data.faultId = faultSelected?._id;
    data.assisted = { parking: isInParking };

    if (faultSelected.deliveryRequired) {
      data.delivery = { notes: data.deliveryNotes || "", address: { ...deliveryAddressRef.current } };
      delete data.deliveryNotes;
    } else {
      delete data.delivery;
      delete data.deliveryNotes;
    }
    try {
      dispatch(serviceUpdateData(data, pickupPlace, deliveryPlace));
      localStorage.setItem("budgetStatus", "pending");
      dispatch(budgetFetchData(data));
    } catch (e) {
      //console.log("RequestForm - Error onSubmit method");
      //console.log(e);
      return null;
    }
  };

  // useEffects

  useEffect(() => {
    if (width < 960) setShowMapView(false);
    else setShowMapView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);

  useEffect(() => {
    if (showMapView) document.getElementById("App").scroll(0, 0);
    else document.getElementById("App").scroll(0, currentScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMapView]);

  useEffect(() => {
    setCurrentHeader("formHeader");

    const utcServiceExpire = localStorage.getItem("utcServiceExpire");
    const utcNow = Date.parse(new Date());

    if (utcServiceExpire && utcServiceExpire <= utcNow) {
      navigate("/", { replace: true });
      return;
    }

    const ss = localStorage.getItem("serviceStatus");
    if (ss) {
      navigate("/servicio", { replace: true });
      return;
    }

    if (!companyId) {
      dispatch(companyFetchData());
    }

    let service = localStorage.getItem("service");
    if (service) {
      service = JSON.parse(service);
      dispatch(serviceUpdateData(service.data, service.servicePickupPlace, service.serviceDeliveryPlace));
    }
    pickupRef.current.tabIndex = -1;
    deliveryRef.current.tabIndex = -1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyId) dispatch(faultsFetchData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (faultsList && serviceData?.faultId) {
      setFaultSelected(faultsList.find(fault => fault._id === serviceData.faultId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faultsList]);

  useEffect(() => {
    if (_.isNull(serviceData)) {
      return;
    }

    if (serviceData) {

      setFaultSelected(faultsList.find(fault => fault._id === serviceData.faultId));

      setIsInParking(serviceData.assisted.parking);

      const formData = {
        ...serviceData,
        pickup: serviceData.pickup.address.fullAddress,
        pickupNotes: serviceData.pickup.notes || "",
        delivery: serviceData.delivery?.address?.fullAddress || "",
        deliveryNotes: serviceData.delivery?.notes || "",
      };

      reset(formData);

      if (serviceData.pickup?.address) getPickupAddress(serviceData.pickup.address);
      if (serviceData.delivery?.address) getDeliveryAddress(serviceData.delivery.address);

      if (serviceData.pickup?.notes) {
        pickupNotesRef.current = serviceData.pickup.notes;
      }
      if (serviceData.delivery?.notes) {
        deliveryNotesRef.current = serviceData.delivery.notes;
      }

      setShowPickupNotes(serviceData.pickup?.notes || false);
      setShowDeliveryNotes(serviceData.delivery?.notes || false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceData]);

  useEffect(() => {
    if (_.isNull(budgetStatus)) return;
    if (budgetStatus === "pending") {
      localStorage.setItem("budgetStatus", "pending");
      localStorage.setItem("comingFrom", "RequestForm");
      navigate("/presupuesto", { replace: true });
      return;
    }
    switch (budgetStatus.code) {
      case "notFoundAvailableResources":
        setShowResourcesNotify(true);
        dispatch(budgetDeleteData());
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetStatus]);

  useEffect(() => {
    if (_.isNull(resourcesInRange)) return;
    if (!resourcesInRange) {
      setShowResourcesNotify(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesInRange]);

  useEffect(() => {
    if (currentHeader === "pickupHeader") {
      pickupRef.current.focus();
    }
    if (currentHeader === "deliveryHeader") {
      deliveryRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHeader]);

  useEffect(() => {
    if (showPickupNotes) {
      //pickupNotesRef.current.focus();
    } else {
      setValue("pickupNotes", null);
      trigger("pickupNotes");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPickupNotes]);

  useEffect(() => {
    setDeliveryKey(deliveryKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryRequired]);

  useEffect(() => {
    if (showDeliveryNotes) {
      //deliveryNotesRef.current.focus();
    } else {
      setValue("deliveryNotes", null);
      trigger("deliveryNotes");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeliveryNotes]);

  // render

  return (

    <div id="App">

      {/* CONTAINER: HEADER */}

      <div key={currentHeader} className={`App__container-header ${currentHeader !== "formHeader" ? "map-below" : ""}`} >

        {currentHeader === "formHeader" && (
          <a className="go-back-icon" onClick={handleReturnToWeb}>
            <img src="/assets/icons/go-back.svg" alt="" />
          </a>
        )}

        {(currentHeader === "pickupHeader" || currentHeader === "deliveryHeader") && (
          <a className="go-back-icon" onClick={handleReturnToForm}>
            <img src="/assets/icons/go-back.svg" alt="" />
          </a>
        )}

        <div className="header-info">{headers[currentHeader]}</div>

        {currentHeader === "formHeader" && (
          <img
            className="company-logo"
            src="/assets/images/logo-color.svg"
            alt=""
          />
        )}

      </div>

      {/* CONTAINER: GLOBAL */}

      <div className="App__container-global flex row h-between">

        {/* CONTAINER: MAP */}

        <div className={`App__container-map ${currentHeader !== "formHeader" ? "show-below" : ""}`}>
          <Map update={true} />
        </div>

        {/* CONTAINER: REQUESTFORM */}

        <div className="App__container-main RequestForm">

          <div className="RequestForm__form">
            <FormProvider {...methods}>
              <form className="" onSubmit={handleSubmit(onSubmit)}>

                {/* pickup & notes */}
                <div className="RequestForm__fields-group">
                  <div className="RequestForm__group-label">
                    <img
                      className="car-icon"
                      src="/assets/icons/car.svg"
                      alt=""
                    />
                    ¿Dónde recogemos el vehículo?
                  </div>
                  <div className={`RequestForm__textfield pickup ${currentHeader === "pickupHeader" ? "map-below" : ""}`}>
                    <InputAddress
                      id={"pickupId"}
                      inputRef={pickupRef}
                      value={pickupPlace}
                      label="Dirección de recogida"
                      name="pickup"
                      required={true}
                      rules={{
                        required: "Dirección de recogida es obligatoria",
                      }}
                      onChange={handleChangeAddress}
                      errorobj={errors}
                    />

                    {(currentHeader === "formHeader" && width < 960) && (
                      <div
                        className="RequestForm__input-shield pickup"
                        onClick={() => setCurrentHeader("pickupHeader")}
                      />
                    )}

                    {(currentHeader === "pickupHeader" || width >= 960) && (
                      <img
                        className={`RequestForm__geolocation-icon gps ${isGeolocationActive ? "gps-active" : "gps-inactive"}`}
                        src={`/assets/icons/crosshair-${isGeolocationActive ? "active" : "inactive"}.svg`}
                        alt=""
                        onClick={getLocation}
                      />
                    )}

                  </div>
                  <div
                    className={`RequestForm__add-notes${showPickupNotes ? " hide" : " show"}`} onClick={() => setShowPickupNotes(true)}>
                    Añadir un comentario
                  </div>
                  <div className={`RequestForm__notes-wrapper${!showPickupNotes ? " hide" : " show"}`} >
                    <TextField
                      className="RequestForm__notes"
                      variant="outlined"
                      multiline={true}
                      minRows={3}
                      maxRows={3}
                      type="text"
                      label="Comentario"
                      name="pickupNotes"
                      inputRef={(e) => {
                        register(e);
                        pickupNotesRef.current = e;
                      }}
                    />
                    <img
                      className="RequestForm__notes-close"
                      src="/assets/icons/close.svg"
                      alt=""
                      onClick={() => setShowPickupNotes(false)}
                    />
                  </div>
                </div>

                {/* fault & parking */}
                <div className="RequestForm__fields-group">

                  {/* fault */}
                  <div className="RequestForm__group-label">¿Qué tipo de avería tienes?</div>
                  <FormControl id="select-control" fullWidth>
                    <InputLabel id="select-placeholder">Tipo de avería</InputLabel>
                    <Select
                      id="select-text"
                      labelId="select-label"
                      variant="outlined"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      value={faultSelected}
                      label="Tipo de avería"
                      name="faultId"
                      onChange={handleChangeFault}
                    >
                      {faultsList.map(fault => (
                        <MenuItem key={fault._id} value={fault}>
                          {fault.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* parking */}
                  <div className="RequestForm__in-parking flex row h-between v-center">
                    <div className="RequestForm__in-parking-label">Está en un Parking</div>
                    <RadioGroup
                      row
                      className="RequestForm__in-parking-radio-group flex row h-between v-center"
                      name="assisted.parking"
                      value={isInParking ? "Sí" : "No"}
                      inputRef={register()}
                      onChange={handleChangeInParking}
                    >
                      {["Sí", "No"].map(item => {
                        return (
                          <div key={item} className="RequestForm__in-parking-radio">
                            <FormControlLabel
                              className="RequestForm__in-parking-radio-label"
                              label={item}
                              value={item}
                              control={<Radio className="RequestForm__in-parking-radio-button" required={true} />}
                            />
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </div>

                </div>

                {/* delivery & notes */}
                <div className={`RequestForm__fields-group ${!showDelivery ? " hide" : ""}`}>
                  <div className="RequestForm__group-label">
                    <img
                      className="flag-icon"
                      src="/assets/icons/flag.svg"
                      alt=""
                    />
                    ¿Dónde lo entregamos?
                  </div>
                  <div className={`RequestForm__textfield delivery ${currentHeader === "deliveryHeader" ? "map-below" : ""}`}>
                    <InputAddress
                      key={deliveryKey}
                      id={"deliveryId"}
                      inputRef={deliveryRef}
                      value={deliveryPlace}
                      label="Dirección de entrega"
                      name="delivery"
                      required={deliveryRequired}
                      rules={deliveryRequired ? { required: "Dirección de entrega es obligatoria" } : null}
                      onChange={handleChangeAddress}
                      errorobj={errors}
                    />

                    {(currentHeader === "formHeader" && width < 960) && (
                      <div
                        className="RequestForm__input-shield delivery"
                        onClick={() => setCurrentHeader("deliveryHeader")}
                      />
                    )}

                  </div>
                  <div className={`RequestForm__add-notes${showDeliveryNotes ? " hide" : " show"}`} onClick={() => setShowDeliveryNotes(true)}>
                    Añadir un comentario
                  </div>
                  <div className={`RequestForm__notes-wrapper${!showDeliveryNotes ? " hide" : " show"}`}>
                    <TextField
                      className="RequestForm__notes"
                      variant="outlined"
                      multiline={true}
                      minRows={3}
                      maxRows={3}
                      type="text"
                      label="Comentario"
                      name="deliveryNotes"
                      inputRef={(e) => {
                        register(e);
                        deliveryNotesRef.current = e;
                      }}
                    />
                    <img
                      className="RequestForm__notes-close"
                      src="/assets/icons/close.svg"
                      alt=""
                      onClick={() => setShowDeliveryNotes(false)}
                    />
                  </div>
                </div>

              </form>
            </FormProvider>

          </div>

          <div className="RequestForm__footer">
            <div className="RequestForm__accept-tac flex h-start v-center">
              <Checkbox className="RequestForm__checkbox" checked={tacAccepted ? true : false} onClick={handleAcceptTac} />
              <div className="RequestForm__accept-tac-text">
                Acepto los <span onClick={() => setShowTac(true)}>Términos y Condiciones</span>
              </div>
            </div>
            <ActionButton
              ClassName="RequestForm__action-button"
              label="Enviar"
              onClick={handleSubmit(onSubmit)}
              disabled={!tacAccepted || !isValid || !pickupPlace || (faultSelected?.deliveryRequired && !deliveryPlace)}
            />
          </div>

        </div>
      </div>

      {/* WARNING AND NOTIFY */}

      {showTac && (
        <ModalTextHtmlFile
          header="Términos y condiciones"
          file="eula.html"
          onClick={() => setShowTac(false)}
        />
      )}

      {showResourcesNotify && (
        <ModalNotify
          modal={modalNotifyContent.notResourcesInRange}
          close={true}
          onClick={handleReturnToWeb}
          onClose={handleCloseResourcesNotify}
        />
      )}

      {showGeolocationNotify && (
        <ModalNotify
          modal={modalNotifyContent[geolocationError]}
          onClick={handleCloseGeolocationNotify}
        />
      )}

    </div>
  );
};

export default RequestForm;
