import { useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";

import "../sharedStyles.scss";
import "./styles.scss";

const stepList = [
  {
    icon: "request",
    text: "Dinos qué necesitas",
    text_aditional: "Completa un sencillo formulario en pocos minutos"
  },
  {
    icon: "accept",
    text: "Acepta el presupuesto y paga",
    text_aditional: "Cotizamos el servicio al instante. Puedes pagarlo con tu tarjeta habitual",
  },
  {
    icon: "relax",
    text: "Relájate y espera",
    text_aditional: "La grúa sale inmediatamente. Para tu tranquilidad, sigue su ubicación en tiempo real"
  },
];

const Steps = () => {

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/solicitud", {replace: true});
  }

  return (
    <div id="solicitud" className="Section bg-gray">
      <div className="Section__wrapper steps">

        <div className="Section__header-text flex column h-center v-center">
          <span>Estás a 3 pasos de ser asistido</span>
        </div>

        <div className="Steps">
          {stepList.map((step, index) => {
            return (
              <div key={step.icon} className="Steps__item flex row h-center v-end">
                <img
                  className="Steps__item-icon"
                  src={`/assets/images/home-steps-${step.icon}.svg`}
                  alt=""
                />

                <div className="Steps__item-text"><span>{index + 1}</span>{step.text}</div>
                <div className="Steps__item-text_aditional">{step.text_aditional}</div>

              </div>
            );
          })}
        </div>

        <div className="Section__request-button-wrapper">
          <Button className="Section__request-button" onClick={onClick}>
            PEDIR GRÚA
          </Button>
        </div>

      </div>
    </div>
  );
};

export default Steps;
