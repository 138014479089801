import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import axios from "axios";
import parse from 'html-react-parser';
import Spinner from "../Spinner";

import "./styles.scss";

const ModalTextHtmlFile = ({ header, file, onClick }) => {

  // redux-store

  const { baseResourcesUrl: baseURL } = useSelector(state => state.entities.company);

  // useState

  const [htmlFileContent, setHtmlFileContent] = useState();

  // useEffect

  useEffect(() => {
    try {
      axios.request({
        baseURL,
        url: file,
        method: "get",
        data: null,
      }).then(result => {
        setHtmlFileContent(result.data);
      }).catch(reject => {
        console.log("REJECT");
        console.log("Promise reject loading html file");
        console.log(reject);
      });
    } catch (error) {
      console.log("Unknown error loading html file");
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  return (

    <div className="ModalTextHtmlFile flex row h-center v-center">
      <div className="ModalTextHtmlFile__wrapper">
        <div className="ModalTextHtmlFile__header-wrapper">
          <img
            className="ModalTextHtmlFile__close-button"
            src="/assets/icons/close.svg"
            alt=""
            onClick={onClick}
          />
          <div className="ModalTextHtmlFile__header">{header}</div>
        </div>
        {(htmlFileContent && (
          <div className="ModalTextHtmlFile__content-wrapper masked-overflow">
            <div className="ModalTextHtmlFile__content">{parse(htmlFileContent)}</div>
          </div>
        )) || <Spinner />
        }
      </div>
    </div>

  );
};

export default ModalTextHtmlFile;
