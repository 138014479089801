import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import parse from 'html-react-parser';

import { Carousel } from 'react-responsive-carousel';

import { Button } from "@material-ui/core";

import "../sharedStyles.scss";
import "./styles.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const carouselOptions = {
  autoPlay: false,
  interval: 0,
  infiniteLoop: false,
  centerMode: false,
  showArrows: false,
  showThumbs: false,
  showStatus: false,
  swipeable: true,
  emulateTouch: true,
  stopOnHover: false,
  transitionTime: 100,
  swipeScrollTolerance: 30,
  preventMovementUntilSwipeScrollTolerance: true,
}

const bgList = [
  {
    header: "<span>Pide una grúa en<u> 3 minutos</u> y síguela<u> en tiempo real</u></span>",
    text: "<span>Solicita asistencia online en pocos minutos. Nuestra red de grúas recogerá tu vehículo, lo reparará in-situ o lo llevará a tu taller, en el menor tiempo posible. Sigue todo el servicio desde nuestra web.</span>",
  },
  {
    header: "<span><u> Reparamos</u> tu vehículo in-situ o<u> lo llevamos</u> al taller</span>",
    text: "<span>Gracias a nuestra amplia experiencia, podemos reparar las averías más comunes allí mismo, para que sigas con tu camino aún más rápido. Si no, llevamos el vehículo a tu taller de confianza.</span>",
  },
  {
    header: "<span><u> Sigue en tiempo real</u> todo el proceso<u> de asistencia</u></span>",
    text: "<span>Para tu tranquilidad, estarás siempre informado de todos los detalles de tu asistencia. Conocerás cada fase, donde se encuentra la grúa y podrás valorar el servicio de tu chófer.</span>",
  },
  {
    header: "<span><u> Revolucionamos</u> la asistencia en carretera<u> con Nexus</u></span>",
    text: "<span>Nos aliamos con Nexus y su tecnología puntera, para convertirnos en líderes de asistencia online en Europa. Aprovecha el servicio de asistencia más completo y eficiente del mercado.</span>",
  }
];

const Welcome = () => {

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/solicitud", { replace: true });
  }

  return (
    <div id="welcome-page" className="Section bg-welcome bg-white">

      <div className="Welcome__header flex h-between v-center">
        <img
          className="Welcome__logo"
          src="/assets/images/logo-color.svg"
          alt=""
        />
        <div className="Welcome__header-hashlinks">
          <HashLink className="Welcome__header-link" smooth to="/#ventajas">Ventajas</HashLink>
          <HashLink className="Welcome__header-link" smooth to="/#solicitud">Solicitud</HashLink>
          <HashLink className="Welcome__header-link" smooth to="/#servicio">Servicio</HashLink>
          <HashLink className="Welcome__header-link" smooth to="/#nosotros">Nosotros</HashLink>
        </div>
        <Button className="Welcome__header-button" onClick={onClick}>PEDIR GRÚA</Button>
      </div>

      <div className="Welcome__body-wrapper">

        <Carousel
          className="Carousel__wrapper" {...carouselOptions}
        >
          {bgList.map((item, index) => {
            return (
              <div key={`bg-${index}`} className="Carousel__item" style={{ backgroundImage: `url("/assets/images/bg-image-${index}.jpg")` }}>
                <div className="Carousel__item-content">
                  <div className="Carousel__item-header">{parse(item.header)}</div>
                  <div className="Carousel__item-text">{parse(item.text)}</div>
                </div>
              </div>
            )
          })}
        </Carousel>

        <Button className="Section__request-button Welcome__request-button" onClick={onClick}>
          PEDIR GRÚA
        </Button>

      </div>

    </div>
  );
};

export default Welcome;
