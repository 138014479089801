import { Button } from "@material-ui/core";

import "./styles.scss";

const ActionButton = ({ label, icon = "", onClick, disabled = true }) => {
  return (
    <Button className="ActionButton" style={{ textTransform: "none" }} onClick={onClick} disabled={disabled}>
      {label}
      {icon !== "" && (
        <img className="ActionButton__icon" src={`/assets/icons/${icon}.svg`} alt="" />
      )}
    </Button>
  );
};

export default ActionButton;
