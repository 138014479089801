import { useState, useEffect, memo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { systemDeleteResourcesInRange } from "../../store/entities/system";

import { companyFetchData } from "../../store/entities/company";
import { faultsFetchData } from "../../store/entities/faults";

import { budgetDeleteData } from "../../store/entities/budget";

import { serviceUpdateData, serviceUpdateStatus, serviceCancel, serviceDeleteData, serviceDeleteStatus } from "../../store/entities/service";

import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import Map from "../../components/Map";

import _ from "lodash";

import ActionButton from "../../components/ActionButton";

import ModalNotify from "../../components/ModalNotify";
import modalNotifyContent from "../../components/ModalNotify/modalNotifyContent";

import "../../styles/global.scss";
import "./styles.scss";

const widgetURL = process.env.REACT_APP_WIDGET_API;

const ServiceMap = memo(Map);

const status = {
  connecting: {
    stepLabel: "conectando con la grúa...",
    statusImage: "towing-connecting",
    header: "Conectando con tu grúa...",
    text: "Estamos conectando en tiempo real con la grúa asignada y enviándole la información de tu solicitud",
    buttonLabel: "Cancelar el servicio"
  },
  accepted: {
    stepLabel: "Servicio aceptado",
    statusImage: "towing-accepted",
    header: "Servicio aceptado, va a empezar",
    text: "Tu grúa ha aceptado el servicio, y está preparándose para ir al punto de recogida",
    buttonLabel: "Cancelar el servicio"
  },
  coming: {
    stepLabel: "Solicitud finalizada",
    statusImage: "towing-coming",
    header: "¡Tu asistencia ha empezado!",
    text: "Pulsa el botón de abajo y entra en la plataforma de seguimiento para ver la grúa, y seguir todo el proceso de asistencia",
    buttonLabel: "Abrir plataforma de seguimiento"
  },
  doing: {
    stepLabel: "Solicitud finalizada",
    statusImage: "towing-doing",
    header: "¡Tu asistencia ha empezado!",
    text: "Pulsa el botón de abajo y entra en la plataforma de seguimiento para ver la grúa, y seguir todo el proceso de asistencia",
    buttonLabel: "Abrir plataforma de seguimiento"
  }
}

let interval;

const Towing = ({ towingStatus = "connecting" }) => {

  const { height, width } = useWindowDimensions();

  // redux-store

  const dispatch = useDispatch();

  const { _id: companyId } = useSelector(state => state.entities.company);
  const { serviceStatus } = useSelector(state => state.entities.service);

  // routing

  let navigate = useNavigate();

  // useState

  const [showMapView, setShowMapView] = useState(false);

  const [budgetId] = useState(localStorage.getItem("budgetId") || null);

  const [currentStatus, setCurrentStatus] = useState(towingStatus);
  const [isStatusGot, setIsStatusGot] = useState(false);

  const [showNotify, setShowNotify] = useState(false);
  const [showResourcesNotify, setShowResourcesNotify] = useState(false);

  const [isTimerActive, setIsTimerActive] = useState(true)  //(_.includes(["connecting", "accepted"], towingStatus) ? true : false);
  const [isCancellable, setIsCancellable] = useState(false);

  const [cancelStatus, setCancelStatus] = useState(null);


  // methods

  const initStore = () => {
    localStorage.removeItem("assisted");
    localStorage.removeItem("budgetId");
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("service");
    localStorage.removeItem("serviceStatus");
    // sosToken
    localStorage.removeItem("utcServiceExpire");
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteData());
    dispatch(serviceDeleteStatus());
    dispatch(systemDeleteResourcesInRange());
    navigate("/", { replace: true });
  };


  const handleReturnToRequestForm = () => {
    // assisted
    localStorage.removeItem("budgetId");
    localStorage.removeItem("budgetStatus");
    // service
    localStorage.removeItem("serviceStatus");
    // sosToken
    localStorage.setItem("utcServiceExpire", Date.parse(new Date()) + (3600 * 1000)); // 1 hour
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteData());
    dispatch(serviceDeleteStatus());
    dispatch(systemDeleteResourcesInRange());
    navigate("/solicitud", { replace: true });
  }


  const handleCancel = (cancelStatusAux) => {
    // isCancellable: [true | false], status (cancelStatus): [ null, "Checking", "Success", "Error"]
    setShowNotify(false);

    if (_.isNull(cancelStatusAux)) {

      if (!isCancellable && isStatusGot) {
        setCancelStatus("Error");
        setShowNotify(true);
      }
      
      else {
        setCancelStatus("Checking");
        dispatch(serviceUpdateStatus(budgetId));
      }


      return;
    }


    if (cancelStatusAux === "Error") {
      setShowNotify(false);
      return;
    }

    if (cancelStatusAux === "Success") {
      setIsTimerActive(false);
      setIsCancellable(false);
      setShowNotify(false);
      setShowResourcesNotify(false);
      initStore();
      return;
    }

  };


  // useEffects

  useEffect(() => {
    if (width < 960) setShowMapView(false);
    else setShowMapView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);

  useEffect(() => {

    const comingFrom = localStorage.getItem("comingFrom");
    localStorage.removeItem("comingFrom");
    if (comingFrom && comingFrom === "Payment") {
      setIsCancellable(true);
      setCurrentStatus("connecting");
      return;
    }

    const utcServiceExpire = localStorage.getItem("utcServiceExpire");
    const utcNow = Date.parse(new Date());
    if (!utcServiceExpire || utcServiceExpire <= utcNow) {
      navigate("/", { replace: true });
      return;
    }

    const ss = localStorage.getItem("serviceStatus");
    if (!ss) {
      initStore();
      return;
    }

    if (ss === "CancelSuccess" || ss === "CancelError") {
      initStore();
      return;
    }

    if (!companyId) {
      dispatch(companyFetchData());
    }

    let service = localStorage.getItem("service");
    if (service) {
      service = JSON.parse(service);
      dispatch(serviceUpdateData(service.data, service.servicePickupPlace, service.serviceDeliveryPlace));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(faultsFetchData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    // serviceStatus: [ null | nexus.currentStatus | CancelSuccess | CancelError ]
    if (_.isNull(serviceStatus)) return;
    if (_.has(serviceStatus, "nexus.currentStatus")) {
      switch (serviceStatus.nexus.currentStatus) {
        case "REJECTED":
          setIsTimerActive(false);
          setIsCancellable(false);
          setShowNotify(false);
          setShowResourcesNotify(true);
          break;
        case "CANCELED":
          setIsTimerActive(false);
          setIsCancellable(false);
          break;
        case "COMPLETED":
        case "FINISHED":
          setIsTimerActive(false);
          setIsCancellable(false);
          setShowNotify(false);
          setShowResourcesNotify(false);
          initStore();
          return;
        case "PENDING":
          setCurrentStatus("connecting");
          setIsCancellable(true);
          if (cancelStatus === "Checking") dispatch(serviceCancel(budgetId));
          break;
        case "ACCEPTED":
          setCurrentStatus("accepted");
          setIsCancellable(true);
          if (cancelStatus === "Checking") dispatch(serviceCancel(budgetId));
          break;
        case "STARTED":
          setIsTimerActive(false);
          setIsCancellable(false);
          setCurrentStatus("coming");
          break;
        default:
          setIsTimerActive(false);
          setIsCancellable(false);
          setCurrentStatus("doing");
          setShowNotify(false);
          setShowResourcesNotify(false);
      }
      setIsStatusGot(true);
    } else {
      if (serviceStatus === "CancelSuccess") {
        setCancelStatus("Success");
        setShowNotify(true);
        return;
      }
      if (serviceStatus === "CancelError") {
        setCancelStatus("Error");
        setShowNotify(true);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceStatus]);

  useEffect(() => {
    if (!isTimerActive) {
      clearInterval(interval);
      interval = null;
      return;
    }
    interval = setInterval(() => {
      dispatch(serviceUpdateStatus(budgetId));
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerActive]);

  useEffect(() => {
    if (!isCancellable && cancelStatus === "Checking") {
      setCancelStatus("Error");
      setShowNotify(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancellable]);

  // render

  return (
    <div id="App">

      {/* CONTAINER: HEADER */}

      <div className="App__container-header" >
        <div className="header-info">
          <div className="header-info__label">{(status[currentStatus].stepLabel).toUpperCase()}</div>
        </div>
        <img
          className="company-logo"
          src="/assets/images/logo-color.svg"
          alt=""
        />
      </div>

      {/* CONTAINER: GLOBAL */}

      <div className="App__container-global flex row h-between">

        {/* CONTAINER: MAP */}

        <div className="App__container-map">{showMapView && <ServiceMap update={false} />}</div>

        {/* CONTAINER: TOWING */}

        <div className="App__container-main Towing">

          <div className="Towing__content">
            <div className="Towing__image">
              <img
                className="Towing__image-main"
                src={`/assets/images/towing-${currentStatus}.svg`}
                alt=""
              />
              {(currentStatus !== "coming" && currentStatus !== "doing") && !showResourcesNotify && (
                <img className={`Towing__image-icon ${currentStatus}`} src={`/assets/icons/${currentStatus}-icon.gif`} alt="" />
              )}
            </div>

            <div className="Towing__header">
              {status[currentStatus].header}
            </div>

            <div className="Towing__text">
              {status[currentStatus].text}
            </div>
          </div>

          <div className="Towing__footer">
            <ActionButton
              label={status[currentStatus].buttonLabel}
              onClick={() => {
                if ((currentStatus !== "coming") && (currentStatus !== "doing")) setShowNotify(true);
                else {
                  localStorage.clear();
                  window.location.href = `${widgetURL}/${serviceStatus.nexus.widget.shortCode}`;
                }
              }}
              disabled={false}
            />
          </div>

        </div>

      </div>

      {/* WARNING AND NOTIFY */}

      {showNotify && (
        <ModalNotify
          modal={modalNotifyContent[`serviceCancel${cancelStatus || ""}`]}
          onClick={() => handleCancel(cancelStatus)}
          close={_.isNull(cancelStatus) ? true : false}
          onClose={() => setShowNotify(false)}
        />
      )}

      {showResourcesNotify && (
        <ModalNotify
          modal={modalNotifyContent.notAvailableTowing}
          onClick={handleReturnToRequestForm}
        />
      )}

    </div>
  );
};

export default Towing;
