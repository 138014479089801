import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../middleware/api";

const slice = createSlice({
  name: "company",

  initialState: {
    _id: null,
    name: null,
    companyCode: null,
    region: null,
    legalId: null,
    type: null,
    baseResourcesUrl: null,
    stripe: null
  },

  reducers: {
    companyDataReceived: (company, action) => {
      localStorage.setItem("sosToken", action.payload.token);
      return { ...company, ...action.payload.company };
    },
  },
});

const { companyDataReceived } = slice.actions;
export default slice.reducer;

// Action creators:

export const companyFetchData = () => dispatch => {
  dispatch(
    apiCallBegan({
      url: "authentication/companyInit",
      method: "post",
      data: { companyCode: process.env.REACT_APP_COMPANY_CODE },
      headers: {
        "Content-Type": "application/json",
      },
      onSuccess: companyDataReceived.type,
    })
  );
};
