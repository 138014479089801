import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../middleware/api";

const slice = createSlice({
  name: "system",

  initialState: {
    showSpinner: false,
    resourcesInRange: null, // null, false or true.
    isAssistedValid: false,
  },

  reducers: {
    systemIsLoadingUpdated: (system, action) => {
      system.showSpinner = action.payload;
    },
    systemResourcesInRangeUpdated: (system, action) => {
      system.resourcesInRange = action.payload.availableResources;
      system.isFetchingData = false;
    },
    systemResourcesInRangeDeleted: (system, action) => {
      system.resourcesInRange = action.payload;
    },
    systemIsAssistedValidUpdated: (system, action) => {
      system.isAssistedValid = action.payload;
    },
  },
});

export const {
  systemIsLoadingUpdated,
  systemResourcesInRangeUpdated,
  systemResourcesInRangeDeleted,
  systemIsAssistedValidUpdated,
} = slice.actions;

export default slice.reducer;

// Action creators:

export const systemIsLoading = showSpinner => dispatch => {
  dispatch(systemIsLoadingUpdated(showSpinner));
};

export const systemCheckResourcesInRange = (coordinates, fullAddress) => dispatch => {
  dispatch(
    apiCallBegan({
      url: `service/checkResourcesInRange?coordinates=[${coordinates}]&fullAddress=${fullAddress}}`,
      method: "get",
      data: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sosToken")}`,
      },
      onSuccess: systemResourcesInRangeUpdated.type,
    })
  );
};

export const systemDeleteResourcesInRange = () => dispatch => {
  dispatch(systemResourcesInRangeDeleted(null));
};

export const systemIsAssistedValid = isAssistedValid => dispatch => {
  dispatch(systemIsAssistedValidUpdated(isAssistedValid));
};
