import { useEffect } from "react";

import { useSelector } from "react-redux";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Landing from "./pages/Landing";
import RequestForm from "./pages/RequestForm";
import Budget from "./pages/Budget";
import Payment from "./pages/Payment";
import Towing from "./pages/Towing";

import Spinner from "./components/Spinner";

import "./styles/bootstrap.scss";
import "./styles/base.scss";
import "./styles/app.scss";

function App() {

  const { showSpinner } = useSelector(state => state.entities.system);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  return (
    <div id="AppWrapper">
      {showSpinner && (<Spinner />)}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/solicitud" element={<RequestForm />} exact />
          <Route path="/presupuesto" element={<Budget />} exact />
          <Route path="/pago" element={<Payment />} exact />
          <Route path="/servicio" element={<Towing />} exact />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
