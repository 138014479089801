import { useState, useEffect, memo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { systemIsLoading, systemCheckResourcesInRange, systemDeleteResourcesInRange, systemIsAssistedValid } from "../../store/entities/system";
import { serviceUpdateData, serviceUpdatePaymentStatus, serviceDeleteData, serviceDeleteStatus } from "../../store/entities/service";
import { budgetDeleteData } from "../../store/entities/budget";

import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import Map from "../../components/Map";

import _ from "lodash";

import StepProgress from "../../components/StepProgress";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useForm, FormProvider, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { TextField } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";

import ModalNotify from "../../components/ModalNotify";
import modalNotifyContent from "../../components/ModalNotify/modalNotifyContent";

import ModalValidationCardNotify from "../../components/ModalValidationCardNotify";
import modalValidationCardNotifyContent from "../../components/ModalValidationCardNotify/modalValidationCardNotifyContent";

import ModalCardNotify from "../../components/ModalCardNotify";
import modalRejectNotifyContent from "../../components/ModalCardNotify/modalRejectNotifyContent";

import "../../styles/global.scss";
import "../RequestForm/styles.scss";
import "./styles.scss";
import "./stripeStyles.scss";

import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

let today = new Date();
today = today.getDate() + "/" + parseInt(today.getMonth() + 1) + "/" + today.getFullYear();
let stripePromise = null;
let assisted = null;

const ServiceMap = memo(Map);

/* STRIPE */

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#808080",
      },
    },
    invalid: {
      color: "#d62e28",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

const CardSection = () => {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

const CheckoutForm = ({ ...props }) => {

  // redux-store

  const dispatch = useDispatch();
  const { isAssistedValid } = useSelector(state => state.entities.system);
  const { _id: budgetId, sosBudget: budgetData } = useSelector(state => state.entities.budget);
  const { serviceStatus } = useSelector(state => state.entities.service);

  const budgetTotalAmount = budgetData.amount + budgetData.deposit;

  // const

  const { stripe, elements } = props;

  // useState

  const [rejectCode, setRejectCode] = useState(null);
  const [showValidationCardNotify, setShowValidationCardNotify] = useState(false);
  const [showRejectNotify, setShowRejectNotify] = useState(false);

  // methods

  const getValidationCardNotify = () => {
    return {
      header: modalValidationCardNotifyContent[rejectCode].header,
      comment: modalValidationCardNotifyContent[rejectCode].comment,
    };
  };

  const getRejectNotify = () => {
    return {
      header: modalRejectNotifyContent[rejectCode].header,
      comment: modalRejectNotifyContent[rejectCode].comment,
    };
  };

  const handleCloseValidationCardNotify = () => {
    dispatch(serviceDeleteStatus());
    setRejectCode(null);
    setShowValidationCardNotify(false);
  };

  const handleCloseRejectNotify = () => {
    dispatch(serviceDeleteStatus());
    setRejectCode(null);
    setShowRejectNotify(false);
  };

  // submit method

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) return;
    const card = elements.getElement(CardElement);
    dispatch(systemIsLoading(true));
    localStorage.setItem("assisted", JSON.stringify(assisted));
    const result = await stripe.createToken(card);
    dispatch(systemIsLoading(false));

    if (result.error) {
      // validation errors
      if (_.has(result.error, "type") && result.error.type === "validation_error") {
        const errorCode = result.error.code;
        setRejectCode(errorCode);
        setShowValidationCardNotify(true);
        return;
      }
    } else {
      dispatch(systemIsLoading(true));
      stripe
        .createPaymentMethod({
          type: 'card',
          card,
        })
        .then(function (result) {
          dispatch(systemIsLoading(false));
          try {
            const servicePaymentDetail = {
              serviceId: budgetId,
              orderAmount: budgetTotalAmount * 100, // Add 00 to total amount
              paymentMethodId: result.paymentMethod.id,
              ...assisted,
            }
            dispatch(serviceUpdatePaymentStatus(servicePaymentDetail));
          } catch (error) {
            // ERROR - SOS
            console.log(error);
            setRejectCode("processing_error");
            setShowRejectNotify(true);
          }
        })
        .catch(function (error) {
          dispatch(systemIsLoading(false));
          // ERROR - STRIPE - CREATE PAYMENT METHOD
          console.log(error);
          setRejectCode("processing_error");
          setShowRejectNotify(true);
        });
    }
  };

  useEffect(() => {
    if (_.isNull(serviceStatus)) return;
    if (_.has(serviceStatus, "requiresAction")) {
      if (serviceStatus.requiresAction) {
        dispatch(systemIsLoading(true));
        stripe
          .handleCardAction(serviceStatus.clientSecret)
          .then(function (result) {
            dispatch(systemIsLoading(false));
            const servicePaymentDetail = {
              serviceId: budgetId,
              paymentIntentId: result.paymentIntent.id,
            }
            dispatch(serviceUpdatePaymentStatus(servicePaymentDetail));
          })
          .catch(function (reject) {
            dispatch(systemIsLoading(false));
            // ERROR - SOS - CARD NOT AUTHENTICATED!
            console.log(reject);
            setRejectCode("authentication_error");
            setShowRejectNotify(true);
          });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceStatus]);

  // render

  return (
    <div id="Stripe__card-info">
      <form onSubmit={handleSubmit}>
        <CardSection />
        {/*<button className="stripe-btn" disabled={!props.stripe || !isAssistedValid} className="btn-pay ">*/}
        <button className={`btn-pay ${!isAssistedValid ? "disabled" : "enabled"}`} disabled={!props.stripe || !isAssistedValid}>
          {`Autorizar ${budgetTotalAmount} €`}
          <img className="btn-pay__icon" src={`/assets/icons/padlock-closed${!isAssistedValid ? "-disabled" : ""}.svg`} alt="" />
        </button>
      </form>
      {showValidationCardNotify && (
        <ModalValidationCardNotify
          modal={getValidationCardNotify()}
          onClick={handleCloseValidationCardNotify}
        />
      )}
      {showRejectNotify && (
        <ModalCardNotify
          modal={getRejectNotify()}
          onClick={handleCloseRejectNotify}
        />
      )}
    </div>
  );
};

/* PAYMENT */

const Payment = () => {

  // resize-observer

  const { height, width } = useWindowDimensions();

  // redux-store

  const dispatch = useDispatch();

  const { stripe } = useSelector(state => state.entities.company);

  const { sosBudget: budgetData } = useSelector(state => state.entities.budget);
  const { serviceStatus } = useSelector(state => state.entities.service);

  const { resourcesInRange } = useSelector(state => state.entities.system);
  const { _id: companyId } = useSelector(state => state.entities.company);
  const { list: faultsList } = useSelector(state => state.entities.faults);
  const { data: serviceData, servicePickupPlace, serviceDeliveryPlace } = useSelector(state => state.entities.service);
  const { status: budgetStatus } = useSelector(state => state.entities.budget);

  // react-hook-form

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      "assisted.contact.phone": "",
    },
  });

  const { formState, register, setValue, trigger, errors, handleSubmit, reset, control, watch } = methods;
  const { isValid } = formState;

  // stripe promise

  stripePromise = !_.isNull(stripe) ? loadStripe(stripe.publishableKey) : null;

  // routing

  let navigate = useNavigate();

  // useState
  
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(null);

  const [showMapView, setShowMapView] = useState(false);

  const [rejectCode, setRejectCode] = useState(null);
  const [showRejectNotify, setShowRejectNotify] = useState(false);
  const [showResourcesNotify, setShowResourcesNotify] = useState(false);

  // methods

  const initStore = () => {
    localStorage.removeItem("assisted");
    localStorage.removeItem("budgetId");
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("service");
    localStorage.removeItem("serviceStatus");
    localStorage.removeItem("utcServiceExpire");
    dispatch(systemIsAssistedValid(false));
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteData());
    dispatch(systemDeleteResourcesInRange());
    navigate("/", { replace: true });
  };

  const getRejectNotify = () => {
    return {
      header: modalRejectNotifyContent[rejectCode].header,
      comment: modalRejectNotifyContent[rejectCode].comment,
    };
  };

  const handleGoBack = () => {
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("serviceStatus");
    localStorage.setItem("assisted", JSON.stringify(assisted));
    dispatch(systemIsAssistedValid(false));
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteStatus());
    navigate("/solicitud", { replace: true });
  };

  const handleCloseResourcesNotify = () => {
    setShowResourcesNotify(false);
    dispatch(systemDeleteResourcesInRange());
  };

  const handleCloseRejectNotify = () => {
    dispatch(serviceDeleteStatus());
    setRejectCode(null);
    setShowRejectNotify(false);
  };

  const handleScrollToElement = event => {
    event.target.scrollIntoView({block: "center", behavior: "smooth"});
    return;
  }

  const handleReturnToRequestForm = () => {
    localStorage.removeItem("budgetStatus");
    localStorage.removeItem("serviceStatus");
    dispatch(budgetDeleteData());
    dispatch(serviceDeleteStatus());
    navigate("/solicitud", { replace: true });
  }

  const handleReturnToWeb = (checkBefore = true) => {
    setShowResourcesNotify(false);
    initStore();
  };

  // useEffect

  useEffect(() => {
    if (width < 960) setShowMapView(false);
    else setShowMapView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width]);


  useEffect(() => {
    const comingFrom = localStorage.getItem("comingFrom");
    localStorage.removeItem("comingFrom");

    const utcServiceExpire = localStorage.getItem("utcServiceExpire");
    const utcNow = Date.parse(new Date());

    if (!comingFrom || comingFrom !== "Budget") {
      if (utcServiceExpire && utcServiceExpire > utcNow) {
        navigate("/solicitud", { replace: true });
        return;
      }
      initStore();
      return;
    }

    if (utcServiceExpire && utcServiceExpire <= utcNow) navigate("/", { replace: true });

    assisted = JSON.parse(localStorage.getItem("assisted")) || null;

    if (_.isNull(assisted)) return;

    reset(assisted);
    //setValue("assisted.contact.phone", assisted.assisted.contact.phone);
    //trigger("assisted.contact.phone");
    document.getElementsByClassName("MuiPhoneNumber-flagButton")[0].tabIndex = -1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_.isNull(serviceStatus)) return;

    if (_.has(serviceStatus, "requiresCapture")) {
      if (serviceStatus.requiresCapture) {
        localStorage.setItem("serviceStatus", "requiresCapture");
        localStorage.setItem("utcServiceExpire", Date.parse(new Date()) + (86400 * 1000)); // 1 day
        localStorage.setItem("comingFrom", "Payment");
        navigate("/servicio", { replace: true });
        return;
      }
    }

    // card rejected
    if (_.has(serviceStatus, "error") && serviceStatus.type === "StripeCardError") {
      const errorCode = serviceStatus.decline_code || serviceStatus.code;
      setRejectCode(errorCode);
      setShowRejectNotify(true);
      return;
    }
    if (_.has(serviceStatus, "statusCode")) {
      if (serviceStatus.statusCode === 400 && serviceStatus.code === "notFoundAvailableResources") {
        setShowResourcesNotify(true);
      }
      if (serviceStatus.statusCode === 400 && serviceStatus.code === "notFoundConfirmResources") {
        setShowResourcesNotify(true);
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceStatus]);

  useEffect(() => {
    assisted = watch();
    dispatch(systemIsAssistedValid(isValid));
  }, [isValid]);

  // render

  return (
    <div id="App">

      {/* CONTAINER: HEADER */}

      <div className="App__container-header" >

        <a className="go-back-icon" onClick={handleGoBack}>
          <img src="/assets/icons/go-back.svg" alt="" />
        </a>

        <div className="header-info">
          <StepProgress step={3} label="Realizar pago" />
        </div>

        <img
          className="company-logo"
          src="/assets/images/logo-color.svg"
          alt=""
        />

      </div>

      {/* CONTAINER: GLOBAL */}

      <div className="App__container-global flex row h-between">

        {/* CONTAINER: MAP */}

        <div className="App__container-map">{showMapView && <ServiceMap update={false} />}</div>

        {/* CONTAINER: PAYMENT */}

        <div className="App__container-main Payment">

        <div className="RequestForm__group-label card">Información de tu tarjeta</div>

          <div>
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => <CheckoutForm stripe={stripe} elements={elements} />}
              </ElementsConsumer>
            </Elements>
          </div>

          {/*// Fields from previous RequestForm page:*/}
          <div className="RequestForm__form">
            <FormProvider {...methods}>
              <form className="">

                {/* personal and vehicle information */}
                <div className="RequestForm__fields-group payment last-group">
                  <div className="RequestForm__group-label">Información personal y del vehículo</div>

                  <div className="RequestForm__input-wrapper">
                    <TextField
                      className="RequestForm__textfield"
                      variant="outlined"
                      type="text"
                      label="Nombre y apellidos"
                      name="assisted.contact.name"
                      inputRef={register({
                        required: "Nombre y apellidos es obligatorio",
                      })}
                      required={true}
                      onFocus={handleScrollToElement}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="assisted.contact.name"
                      render={({ message }) => <div className="RequestForm__error-message">{message}</div>}
                    />
                  </div>

                  <Controller
                    as={
                      <MuiPhoneNumber
                        className="RequestForm__phone-number phone-number"
                        variant="outlined"
                        defaultCountry={"es"}
                        countryCodeEditable={false}
                        regions={["europe"]}
                        preferredCountries={["es", "pt", "fr", "it", "de"]}
                        type="tel"
                        label="Teléfono"
                        name="assisted.contact.phone"
                        autoComplete="nope"
                        ref={register()}
                        required={true}
                        onFocus={handleScrollToElement}
                      />
                    }
                    name={"assisted.contact.phone"}
                    control={control}
                  />
                  {!_.isNull(isPhoneNumberValid) && !isPhoneNumberValid &&
                    (<div className="RequestForm__error-message">Formato de teléfono incompleto o erróneo</div>
                    )}

                  <div className="RequestForm__input-wrapper">
                    <TextField
                      id="serviceEmail"
                      className="RequestForm__textfield"
                      variant="outlined"
                      type="email"
                      label="Email"
                      name="assisted.contact.email"
                      inputRef={register({
                        required: "El campo email es obligatorio",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Dirección de email no válida",
                        },
                      })}
                      onFocus={handleScrollToElement}
                      required={true}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="assisted.contact.email"
                      render={({ message }) => <div className="RequestForm__error-message">{message}</div>}
                    />
                  </div>

                  <TextField
                    className="RequestForm__textfield"
                    variant="outlined"
                    type="text"
                    label="Marca y modelo"
                    name="assisted.vehicle.brand"
                    inputRef={register()}
                    onFocus={handleScrollToElement}
                  />

                  <div className="RequestForm__input-wrapper">
                    <TextField
                      className="RequestForm__textfield"
                      variant="outlined"
                      type="text"
                      label="Matrícula"
                      name="assisted.vehicle.plate"
                      inputRef={register({
                        required: "Matrícula es un campo obligatorio",
                        /*
                        pattern: {
                          value: /^[0-9]{1,4}(?!.*(LL|CH))[BCDFGHJKLMNPRSTVWXYZ]{3}$/i,
                          message: "Matrícula incompleta o errónea",
                        },
                        */
                      })}
                      required={true}
                      onFocus={handleScrollToElement}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="assisted.vehicle.plate"
                      render={({ message }) => <div className="RequestForm__error-message">{message}</div>}
                    />
                  </div>

                  <TextField
                    className="RequestForm__textfield"
                    variant="outlined"
                    type="text"
                    label="Color"
                    name="assisted.vehicle.color"
                    inputRef={register()}
                    onFocus={handleScrollToElement}
                  />

                  <div className="RequestForm__info-required">*Los campos con (*) son obligatorios</div>

                </div>

              </form>
            </FormProvider>

          </div>
          {/*// End of fields from previous RequestForm page*/}


        </div>

      </div>


      {/* WARNING AND NOTIFY */}

      {showRejectNotify && (
        <ModalCardNotify
          modal={getRejectNotify()}
          onClick={handleCloseRejectNotify}
        />
      )}

      {showResourcesNotify && (
        <ModalNotify
          modal={modalNotifyContent.notAvailableTowing}
          onClick={handleReturnToRequestForm}
        />
      )}

    </div>
  );
};

export default Payment;