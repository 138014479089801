import { combineReducers } from "redux";

import systemReducer from "./system";
import companyReducer from "./company";
import faultsReducer from "./faults";
import budgetReducer from "./budget";
import serviceReducer from "./service";


export default combineReducers({
  system: systemReducer,
  company: companyReducer,
  faults: faultsReducer,
  budget: budgetReducer,
  service: serviceReducer,
});
