import { useState, useEffect, useRef } from "react";
import Input from "../Input";

import _ from "lodash";

const google = (window.google = window.google ? window.google : {});

let isPredictionSelected;

const InputAddress = ({ id, onChange, label, required, value, name, ...props }) => {

  const placesRef = useRef();
  isPredictionSelected = useState(_.isNull(value) ? false : true);

  const [place, setPlace] = useState(value || null);

  const initPlaces = () => {
    const input = placesRef.current.children[0].children[0].children[1].children[0];

    const predictionList = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: "es" },
      types: ["geocode"],
      language: "es",
      fields: ["address_components", "geometry.location", "formatted_address"],
    });

    predictionList.addListener("place_changed", () => {
      const _place = predictionList.getPlace();
      setPlace({ ..._place });
    });

  };

  const handleChange = () => {
    const input = placesRef.current.children[0].children[0].children[1].children[0];
    const inputValue = input.value;
    isPredictionSelected = false;
    input.value = inputValue;
  };

  const handleFocus = () => {
    // ...
  };

  const handleBlur = () => {

    if (!isPredictionSelected) {
      const input = placesRef.current.children[0].children[0].children[1].children[0];
      onChange(null, null, name, false, false);
      input.value = "";
    }
  };

  useEffect(() => {
    if (place) {
      if (name === "pickup") onChange(place.formatted_address, place, "pickup", true, false);
      if (name === "delivery") onChange(place.formatted_address, place, "delivery", false, false);
      isPredictionSelected = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  useEffect(() => {
    initPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="InputAddress" ref={placesRef}>
      <Input
        id={id}
        label={label}
        name={name}
        placeholder="&nbsp;"
        value={value}
        required={required}
        handleChange={handleChange}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        onReset={() => setPlace(null)}
        {...props}
      />
    </div>
  );
};

export default InputAddress;
