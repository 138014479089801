import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/api";

const slice = createSlice({
  name: "service",

  initialState: {
    data: null,
    servicePickupPlace: null,
    serviceDeliveryPlace: null,
    serviceStatus: null,
    serviceExpire: null
  },

  reducers: {
    serviceDataUpdated: (service, action) => {
      localStorage.setItem("service", JSON.stringify({ ...service, ...action.payload }));
      localStorage.setItem("utcServiceExpire", Date.parse(new Date()) + (3600 * 1000)); // 1 hour
      return { ...service, ...action.payload };
    },
    serviceDataDeleted: (service, action) => {
      return { ...service, data: null, servicePickupPlace: null, serviceDeliveryPlace: null, serviceStatus: null, serviceExpire: null };
    },
    servicePickupUpdated: (service, action) => {
      return { ...service, servicePickupPlace: action.payload };
    },
    serviceDeliveryUpdated: (service, action) => {
      return { ...service, serviceDeliveryPlace: action.payload };
    },
    serviceStatusUpdated: (service, action) => {
      localStorage.setItem("serviceStatus", { ...action.payload});
      return { ...service, serviceStatus: { ...action.payload }}
    },
    serviceStatusDeleted: (service, action) => {
      localStorage.removeItem("serviceStatus");
      return { ...service, serviceStatus: null }
    },
    serviceSuccessCanceled: (service, action) => {
      localStorage.setItem("serviceStatus", "CancelSuccess");
      return { ...service, serviceStatus: "CancelSuccess" }
    },
    serviceErrorCanceled: (service, action) => {
      localStorage.setItem("serviceStatus", "CancelError");
      return { ...service, serviceStatus: "CancelError" };
    },
  },
});

const {
  serviceDataUpdated,
  serviceDataDeleted,
  servicePickupUpdated,
  serviceDeliveryUpdated,
  serviceStatusUpdated,
  serviceStatusDeleted,
  serviceSuccessCanceled,
  serviceErrorCanceled,
} = slice.actions;

export default slice.reducer;



// Action creators:

export const serviceUpdateData = (data, pickupPlace, deliveryPlace) => dispatch => {
  dispatch(serviceDataUpdated({
    data,
    servicePickupPlace: pickupPlace,
    serviceDeliveryPlace: deliveryPlace,
  }));
}

export const serviceDeleteData = () => dispatch => {
  dispatch(serviceDataDeleted());
}

export const serviceUpdatePickup = (pickupPlace) => dispatch => {
  dispatch(servicePickupUpdated(pickupPlace));
}

export const serviceUpdateDelivery = (deliveryPlace) => dispatch => {
  dispatch(serviceDeliveryUpdated(deliveryPlace));
}

export const serviceUpdatePaymentStatus = (servicePaymentDetail) => dispatch => {
  dispatch(
    apiCallBegan({
      url: "stripe/deposit",
      method: "post",
      data: servicePaymentDetail,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sosToken")}`,
      },
      onSuccess: serviceStatusUpdated.type,
      onError: serviceStatusUpdated.type,
    })
  );
};

export const serviceUpdateStatus = (serviceId) => dispatch => {
  dispatch(
    apiCallBegan({
      url: `service/serviceStatus/${serviceId}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sosToken")}`,
      },
      onSuccess: serviceStatusUpdated.type,
      onError: serviceStatusUpdated.type,
    })
  );
};

export const serviceDeleteStatus = () => dispatch => {
  dispatch(serviceStatusDeleted(null));
};

export const serviceCancel = (serviceId) => dispatch => {
  dispatch(
    apiCallBegan({
      url: "service/serviceCancel",
      method: "post",
      data: {
        serviceId,
        cancelNotes: "Cancelado a petición del usuario."
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sosToken")}`,
      },
      onSuccess: serviceSuccessCanceled.type,
      onError: serviceErrorCanceled.type,
    })
  );
};
