import { Button } from "@material-ui/core";

import "./styles.scss";

const ModalValidationCardNotify = ({ modal, onClick }) => {
  return (
    <div className="ModalValidationCardNotify flex row h-center v-center">
      <div className="ModalValidationCardNotify__wrapper">
        <div className="ModalValidationCardNotify__main-header">Error en tu tarjeta</div>
        <div className="ModalValidationCardNotify__header">{modal.header}</div>
        <div className="ModalValidationCardNotify__comment">{modal.comment}</div>
        <Button className="ModalValidationCardNotify__action-button" style={{ textTransform: "none" }} onClick={onClick}>
          Aceptar
        </Button>
      </div>
    </div>
  );
};

export default ModalValidationCardNotify;
