import { useState } from "react";

import ModalTextHtmlFile from "../../../components/ModalTextHtmlFile";

import "../sharedStyles.scss";
import "./styles.scss";

const Legal = () => {

  // useState

  const [showTac, setShowTac] = useState(false);

  return (
    <div className="Section bg-company-legal">
      <div className="Legal flex column h-center">
        <div className="Legal__text-legal tac" onClick={() => setShowTac(true)}>
          Términos y condiciones
        </div>
        <div className="Legal__text-legal">
          Cookies
        </div>
        <div className="Legal__text-legal">
          ©2022 Pedirunagrua Inc.
        </div>
      </div>

      {showTac && <ModalTextHtmlFile header="Términos y condiciones" file="eula.html" onClick={() => setShowTac(false)} />}

    </div>
  );
};

export default Legal;
